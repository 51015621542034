import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import size from "lodash/size";
import trim from "lodash/trim";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import CloseIcon from "@mui/icons-material/Close";

import { FormFileField } from "components/common/FormFields";

import { addNotification } from "redux/reducers/notification.reducer";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import { importUser } from "gis_user/data/services";

const UserImportForm = ({ onClose, refetch }) => {
  /**
   * Parent:
   *    UserListPage
   */
  const dispatch = useDispatch();
  const [errorList, setErrorList] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [messageType, setMessageType] = useState(null);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: { file: null },
  });

  const { mutate: importUserMutation, isLoading: loadingImportuser } =
    useMutation(importUser, {
      onError: (err) => {
        onClose();
        const { fieldList, messageList } = parseErrorMessagesWithFields(err);
        for (let index = 0; index < fieldList.length; index++) {
          const field = fieldList[index];
          const errorMessage = messageList[index];
          dispatch(
            addNotification({
              type: "error",
              title: field,
              text: errorMessage,
            })
          );
        }
      },
      onSuccess: (res) => {
        const success_count = get(res, "success_count", 0);
        const error_list = get(res, "error_list", []);
        // check success and error count, update msg and error list accordingly.
        if (error_list?.length) {
          let successMsg = "";
          if (success_count) {
            successMsg = `${success_count} user update are completed.`;
          }

          if (error_list?.length) {
            successMsg =
              successMsg + ` ${error_list?.length} user update are failed`;
          }

          setSuccessMsg(trim(successMsg));
          setMessageType("warning.main");
          setErrorList(error_list);
        }
        //
        else {
          let successMsg = `${success_count} user update are completed.`;

          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.SUCCESS,
              title: "Upload Excel",
              text: trim(successMsg),
            })
          );
          onClose();
        }
        refetch();
      },
    });

  const onFormSubmit = useCallback((formData) => {
    setErrorList([]);
    setSuccessMsg("");
    setMessageType(null);

    const data = new FormData();
    data.append("file", formData.file, formData.file.name);
    importUserMutation(data);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Box />
        <Typography variant="h6" color="primary.dark">
          Import User Excel
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent
        dividers
        sx={{
          padding: 0,
        }}
      >
        <Box p={4} pr={10}>
          <Stack direction="row">
            <Box py={1.5} flex={1} display="flex" justifyContent="center">
              <FormFileField
                label="Upload XLSX file"
                name="file"
                control={control}
                rules={{
                  required: "File is required",
                }}
                required={true}
                error={!!get(errors, "file")}
                helperText={get(errors, "file.message", "")}
              />
            </Box>
          </Stack>
        </Box>
        {!!size(errorList) ? (
          <Box>
            <Typography
              variant="h6"
              color="primary.dark"
              textAlign="center"
              py={1}
            >
              Errors
            </Typography>
            <Divider />
            {successMsg ? (
              <Typography
                variant="body2"
                color={messageType}
                textAlign="center"
                py={2}
              >
                {successMsg}
              </Typography>
            ) : null}
            <TableContainer
              component={Paper}
              sx={{
                width: "88%",
                margin: "0 auto",
                marginBottom: "1em",
                // marginTop: "1em",
              }}
            >
              <Table stickyHeader size="small" aria-label="sticky dense table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "90px" }}>Index</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorList.map((error, index) => {
                    let errMsg = error.message;

                    if (errMsg && typeof errMsg === "object") {
                      errMsg = JSON.stringify(errMsg);
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ width: "90px" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell>{errMsg}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          onClick={handleSubmit(onFormSubmit)}
          loading={loadingImportuser}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

export default UserImportForm;
