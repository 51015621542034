import React, { useCallback, useState, useMemo } from "react";
import { useQuery, useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { startOfDay, endOfDay } from "date-fns";
import size from "lodash/size";
import map from "lodash/map";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import isNull from "lodash/isNull";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack, Chip, Typography, Box } from "@mui/material";

import { CustomDatePicker } from "components/common/FormFields";
import CustomSelect from "components/common/FormFields/CustomSelect";
import { fetchRegionList } from "region/data/services";

import {
  fetchAlertReport,
  fetchTriggercodeList,
} from "Analysis/data/analysis.service";
import { addNotification } from "redux/reducers/notification.reducer";
import {
  getIsSuperAdminUser,
  getUserPermissions,
} from "redux/selectors/auth.selectors";
import {
  fetchLayerData,
  fetchLayerList,
} from "planning/data/actionBar.services";
import { ALERT_POSSIBLE_LAYER_KEYS } from "pages/Dashboard/dashboard.utils";

const REPORT_SEVERITY_OPTIONS = [
  { value: "I", label: "Information" },
  { value: "W", label: "Warning" },
  { value: "H", label: "High" },
  { value: "C", label: "Critical" },
  { value: "D", label: "Disaster" },
];

const REPORT_STATUS_OPTIONS = [
  { value: "P", label: "PROBLEM" },
  { value: "R", label: "RESOLVED" },
  { value: "C", label: "CLOSED" },
];

const ALERT_CATEGORY_FILTER = [
  { value: true, label: "Telco" },
  { value: false, label: "Non-Telco" },
];

const AlertReport = () => {
  const dispatch = useDispatch();

  const [region, setRegion] = useState(null);
  const [layer, setLayer] = useState(null);
  const [element, setElement] = useState(null);
  const [isAdvance, setAdvance] = useState(false);
  const [status, setStatus] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [problemName, setProblemName] = useState("");
  const [triggerCode, setTriggerCode] = useState(null);
  const [isTelco, setIsTelco] = useState(null);

  const permissions = useSelector(getUserPermissions);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);

  const { isLoading: regionListLoading, data: regionList = [] } = useQuery(
    ["regionList", "data"],
    fetchRegionList
  );

  const { mutate: exportMutation, isLoading: isFileLoading } =
    useMutation(fetchAlertReport);

  const { isLoading, data: layerCofigs = [] } = useQuery(
    "planningLayerConfigs",
    fetchLayerList,
    {
      staleTime: Infinity,
    }
  );

  const { isLoading: fetchingTriggerCode, data: triggerCodeList = [] } =
    useQuery("fetchTriggercodeList", fetchTriggercodeList, {
      staleTime: 5000,
      select: (res) => map(res, (d) => ({ label: d, value: d })),
    });

  const {
    mutate: fetchLayerDataMutation,
    isLoading: fetchingLayerData,
    data: elementList,
  } = useMutation(fetchLayerData);

  const handleRegionChange = useCallback((newValue) => {
    setRegion(newValue);
    setAdvance(false);
    setLayer(null);
    setElement(null);
    setSeverity([]);
    setStatus([]);
  }, []);

  const handleLayerChange = useCallback(
    (newValue) => {
      setLayer(newValue);
      setElement(null);
      if (!newValue) return;
      fetchLayerDataMutation({
        regionIdList: [region.id],
        layerKey: newValue.layer_key,
      });
    },
    [region]
  );

  const handleElementChange = useCallback((newValue) => {
    setElement(newValue);
  }, []);

  const handleSeverityToggle = useCallback(
    (newSeverity) => () => {
      if (severity.includes(newSeverity)) {
        setSeverity([]);
      } else {
        setSeverity([newSeverity]);
      }
    },
    [severity]
  );

  const handleStatusToggle = useCallback(
    (newStatus) => () => {
      if (status.includes(newStatus)) {
        setStatus([]);
      } else {
        setStatus([newStatus]);
      }
    },
    [status]
  );

  const handleTalcoToggle = useCallback(
    (newValue) => () => {
      setIsTelco(newValue === isTelco ? null : newValue);
    },
    [isTelco]
  );

  const handleAdvanceChange = useCallback(() => {
    setAdvance(!isAdvance);
  }, [isAdvance]);

  const handleTriggerCodeChange = useCallback((newValue) => {
    setTriggerCode(newValue);
  }, []);

  const handleSubmit = () => {
    let data;
    if (!region) {
      dispatch(
        addNotification({
          type: "error",
          title: "Please select region",
        })
      );
      return;
    }
    // if (!(startTime || endTime)) {
    //   dispatch(
    //     addNotification({
    //       type: "error",
    //       title: "Please select start time or end time",
    //     })
    //   );
    //   return;
    // }

    // show error if layer selectec but element is not selected
    if (isAdvance && layer && !element) {
      dispatch(
        addNotification({
          type: "error",
          title: "Select Device for layer, or remove layer selection",
        })
      );
      return;
    }

    data = {
      return_type: "xlsx",
      region_id: region.id,
      problem_name_fuzzy: isAdvance ? problemName : undefined,
      triggercode: isAdvance ? triggerCode?.value : undefined,
      status: status,
      severity: severity,
      layer_key: layer ? layer.layer_key : undefined,
      element_id: element ? element.id : undefined,
      start_time: startTime ? startOfDay(startTime) : undefined,
      end_time: endTime ? endOfDay(endTime) : undefined,
    };

    if (!isNull(isTelco)) {
      data["is_telco"] = isTelco;
    }

    const reportFileName = `${region.name}_${status.join("_")}_${severity.join(
      "_"
    )}_alert_report`;

    exportMutation(data, {
      onSuccess: (res) => {
        const fileBlob = new Blob([res], {
          type: res.type,
        });
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `${reportFileName}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Error",
            text: err.message,
          })
        );
      },
    });
  };

  const possibleLayerKeys = useMemo(() => {
    return filter(layerCofigs, (config) => {
      const layerKey = config.layer_key;
      return (
        (get(permissions, `${layerKey}_view`) || isSuperAdminUser) &&
        includes(ALERT_POSSIBLE_LAYER_KEYS, layerKey)
      );
    });
  }, [layerCofigs]);

  return (
    <Stack sx={{ margin: "0 auto", maxWidth: "480px" }} spacing={2}>
      <CustomSelect
        label="Select Region"
        name="region"
        options={regionList}
        isClearable
        isSearchable
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.id}
        menuPortalTarget={document.body}
        isLoading={regionListLoading}
        value={region}
        onChange={handleRegionChange}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdvance}
              onChange={handleAdvanceChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Advance"
        />
      </FormGroup>
      {isAdvance ? (
        <Stack spacing={2}>
          <TextField
            className="full-width"
            // variant="standard"
            label="Problem Name"
            value={problemName}
            onChange={(e) => setProblemName(e.target.value)}
          />
          <CustomSelect
            label="Select Layer"
            name="layer"
            options={possibleLayerKeys}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.layer_key}
            menuPortalTarget={document.body}
            isLoading={isLoading}
            value={layer}
            onChange={handleLayerChange}
            isDisabled={!size(region)}
          />
          <CustomSelect
            label="Select Element"
            name="element"
            options={layer && size(elementList) ? elementList : []}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            menuPortalTarget={document.body}
            isLoading={fetchingLayerData}
            value={element}
            onChange={handleElementChange}
            isDisabled={!size(layer)}
          />
          <CustomSelect
            label="Select Trigger Code"
            name="triggercode"
            options={triggerCodeList ? triggerCodeList : []}
            isClearable
            isSearchable
            menuPortalTarget={document.body}
            isLoading={fetchingTriggerCode}
            value={triggerCode}
            onChange={handleTriggerCodeChange}
          />
        </Stack>
      ) : null}
      <Box>
        <Typography variant="caption" pb={1} component="div">
          Severity
        </Typography>
        <Stack direction="row" spacing={1}>
          {REPORT_SEVERITY_OPTIONS.map((currSeverity) => {
            return (
              <Chip
                label={currSeverity.label}
                key={currSeverity.value}
                onClick={handleSeverityToggle(currSeverity.value)}
                variant={"outlined"}
                color={
                  severity.includes(currSeverity.value) ? "primary" : undefined
                }
                sx={{
                  minWidth: "54px",
                }}
              />
            );
          })}
        </Stack>
      </Box>
      <Box>
        <Typography variant="caption" pb={1} component="div">
          Status
        </Typography>
        <Box display="flex" direction="row" spacing={1} flexWrap="wrap">
          {REPORT_STATUS_OPTIONS.map((currStatus) => {
            return (
              <Chip
                label={currStatus.label}
                key={currStatus.value}
                onClick={handleStatusToggle(currStatus.value)}
                variant={"outlined"}
                color={
                  status.includes(currStatus.value) ? "primary" : undefined
                }
                sx={{
                  minWidth: "54px",
                  marginTop: 1,
                  marginRight: 1,
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Box>
        <Typography variant="caption" pb={1} component="div">
          Alert Category
        </Typography>
        <Box display="flex" direction="row" spacing={1} flexWrap="wrap">
          {ALERT_CATEGORY_FILTER.map((currStatus) => {
            return (
              <Chip
                label={currStatus.label}
                key={currStatus.value}
                onClick={handleTalcoToggle(currStatus.value)}
                variant={"outlined"}
                color={isTelco === currStatus.value ? "primary" : undefined}
                sx={{
                  minWidth: "54px",
                  marginTop: 1,
                  marginRight: 1,
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Box width="100%" mt={3} pt={1}>
        <CustomDatePicker
          label="Start Date"
          value={startTime}
          onChange={setStartTime}
        />
      </Box>
      <Box width="100%" mt={2}>
        <CustomDatePicker
          label="End Date"
          value={endTime}
          onChange={setEndTime}
        />
      </Box>
      <LoadingButton
        variant="outlined"
        color="secondary"
        onClick={handleSubmit}
        loading={isFileLoading}
      >
        Download
      </LoadingButton>
    </Stack>
  );
};

export default AlertReport;
