import React, { useCallback, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import size from "lodash/size";
import map from "lodash/map";
import { format, startOfDay, endOfDay } from "date-fns";

import CustomSelect from "components/common/FormFields/CustomSelect";
import { CustomDatePicker } from "components/common/FormFields";

import { fetchRegionList } from "region/data/services";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  fetchCustomerTaggingProgressReport,
  fetchCustomerTaggingProgressThunk,
} from "Analysis/data/analysis.service";
import { fetchLayerData } from "planning/data/actionBar.services";

import { addNotification } from "redux/reducers/notification.reducer";
import { LAYER_KEY as FsaLayerKey } from "planning/GisMap/layers/p_fsa";

import {
  getReportType,
  getReportDataState,
  getReportFormUniqueId,
} from "Analysis/data/analysis.selectors";
import {
  setReportFormUniqueId,
  resetReportData,
} from "Analysis/data/analysis.reducer";

const CustomerTaggingCpmoletionReportForm = () => {
  /**
   * Parent:
   *    AnalysisForm
   */
  const dispatch = useDispatch();
  const reportFormUniqueId = useSelector(getReportFormUniqueId);
  const reportType = useSelector(getReportType);
  const reportStateData = useSelector(getReportDataState);
  const {
    fetching: reportFetching,
    fetched: reportFetched,
    reportData,
  } = reportStateData;

  const [regions, setRegions] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isAdvance, setAdvance] = useState(false);
  const [elements, setElements] = useState([]);
  const [fileType, setFileType] = useState(null);

  const { isLoading: regionListLoading, data: regionList = [] } = useQuery(
    ["regionList", "data"],
    fetchRegionList
  );

  const {
    mutate: fetchLayerDataMutation,
    isLoading: fetchingLayerData,
    data: elementList,
  } = useMutation(fetchLayerData);

  const {
    mutate: fetchCustomerTaggingCompletionMutation,
    isLoading: isReportLoading,
  } = useMutation(fetchCustomerTaggingProgressReport, {
    onSuccess: (res) => {
      setFileType(null);
      const fileBlob = new Blob([res], {
        type: res.type,
      });
      const url = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = url;
      const reportName =
        reportType?.label +
        "_" +
        format(new Date(), "dd/MM/yyyy") +
        "_" +
        format(new Date(), "hh:mm:ss");
      link.setAttribute("download", `${reportName}.xlsx`);
      // have to add element to doc for firefox
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    onError: (err) => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error",
          text: err.message,
        })
      );
    },
  });

  const handleRegionChange = useCallback((newValue) => {
    setRegions(newValue);
    setAdvance(false);
    fetchLayerDataMutation({
      regionIdList: map(newValue, "id"),
      layerKey: FsaLayerKey,
    });
  }, []);

  const handleAdvanceChange = useCallback(() => {
    setAdvance(!isAdvance);
  }, [isAdvance]);

  const handleElementChange = useCallback((newValue) => {
    setElements(newValue);
  }, []);

  const handleSubmit = (currFileType) => () => {
    setFileType(currFileType);

    if (!size(regions)) {
      dispatch(
        addNotification({
          type: "error",
          title: "Please select region",
        })
      );
      return;
    }

    let postData = {
      region_ids: map(regions, "id"),
      is_advanced: !!isAdvance, // if this is true user can further select multiple fsa of given region
      element_ids: map(elements, "id"), // list of fsa ids
      start_time: startOfDay(startTime),
      end_time: endOfDay(endTime),
    };

    const currIdentifier = JSON.stringify(postData);
    // check region is same
    const isIdentifierSame = reportFormUniqueId === currIdentifier;
    const hasData = isIdentifierSame && reportFetched && !!size(reportData);

    // pass report data is user requested with same params
    if (hasData) {
      postData["has_data"] = hasData;
      postData["report_data"] = reportData;
    } else {
      // otherwise reset report data
      dispatch(resetReportData());
    }

    dispatch(setReportFormUniqueId(currIdentifier));
    // update return type
    postData["return_type"] = currFileType;

    if (currFileType === "xlsx") {
      fetchCustomerTaggingCompletionMutation(postData);
    } else {
      dispatch(fetchCustomerTaggingProgressThunk(postData));
    }
  };

  return (
    <Stack sx={{ margin: "0 auto", maxWidth: "480px" }} spacing={2}>
      <CustomSelect
        label="Select Region"
        name="region"
        options={regionList}
        isClearable
        isSearchable
        isMulti
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.id}
        menuPortalTarget={document.body}
        isLoading={regionListLoading}
        value={regions}
        onChange={handleRegionChange}
      />
      <Stack direction="row" spacing={1}>
        <Box width="100%">
          <CustomDatePicker
            label="Start Date"
            value={startTime}
            onChange={setStartTime}
          />
        </Box>
        <Box width="100%">
          <CustomDatePicker
            label="End Date"
            value={endTime}
            onChange={setEndTime}
          />
        </Box>
      </Stack>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdvance}
              onChange={handleAdvanceChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Advance"
        />
      </FormGroup>
      {isAdvance ? (
        <>
          <CustomSelect
            label="Select FSA"
            name="element"
            options={size(elementList) ? elementList : []}
            isClearable
            isSearchable
            isMulti
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            menuPortalTarget={document.body}
            isLoading={fetchingLayerData}
            value={elements}
            onChange={handleElementChange}
            isDisabled={!size(regions)}
          />
        </>
      ) : null}

      <Stack direction="row" spacing={1}>
        <LoadingButton
          sx={{ flex: 1 }}
          variant="outlined"
          color="secondary"
          onClick={handleSubmit("xlsx")}
          loading={isReportLoading && fileType === "xlsx"}
        >
          Download XLSX
        </LoadingButton>
        <LoadingButton
          sx={{ flex: 1 }}
          variant="outlined"
          color="secondary"
          onClick={handleSubmit("json")}
          loading={reportFetching}
        >
          Generate Report
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default CustomerTaggingCpmoletionReportForm;
